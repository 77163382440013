<template>
	<div class="">
		<el-dialog
			v-loading="listLoading"
			:visible.sync="isActiveSendHealthPackageVisible"
			width="640px"
			inline
			:before-close="handleCancel">
			<span slot="title" style="font-size: 14px;color: #606266;font-weight: 700;">发放权益</span>
			
			<el-form :model="ruleForm"
							 :rules="rules"
							 ref="ruleForm"
							 size="mini"
							 label-position="top"
							 label-width="100px"
							 class="ruleForm">
				<el-form-item label="选择医生" prop="doctor_id" style="margin-bottom: 10px" class="from-item-third">
					<el-select
						v-model="ruleForm.doctor_id"
						placeholder="请选择"
						@change="handleDoctorChangeSelect">
						<el-option
							v-for="item in doctorList"
							:key="item.id"
							:label="item.doctor_name"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="服务包" prop="demand_id" style="margin-bottom: 10px" class="from-item-third">
					<el-select
						v-model="ruleForm.demand_id"
						placeholder="请选择"
						@change="handleHealthPackageChangeSelect">
						<el-option
							v-for="item in demandList"
							:key="item.id"
							:label="item.content"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			
			
			<div slot="footer" class="dialog-footer" >
				<el-button class="mgr-20" size="mini" @click="handleCancel">取 消</el-button>
				<el-button size="mini" type="primary" @click="submitForm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {getDoctorDemandList, getDemandList, postPatientSendDemand} from '@/api/cdm/cdm-patient.js'

export default {
  name: "CDMPatientManageDialogs",
	props: {
    isActiveSendHealthPackageVisible: {
      type: Boolean,
			default: false
		},
    patientInfo: {
      type: Object,
			default: () => {}
		}
	},
	created () {
    this.getList()
  },
  data() {
    return {
      listLoading: false,
      doctorList: [],
      demandList: [],
      ruleForm: {
        doctor_id: '',
        demand_id: ''
      },
      rules: {
        doctor_id: [
          { required: true, message: '请选择医生', trigger: 'blur' }
        ],
        demand_id: [
          { required: true, message: '请选择套餐包', trigger: 'blur' }
        ],
      }
		}
	},
	methods: {
    getList (rest) {
      if (rest === 'restPage') {
        this.listQuery.page = 1
      }
      this.listLoading = true
      getDoctorDemandList().then(response => {
        let data = response
        if (data.code === 200) {
          this.doctorList = data.data
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    _getDemandList(id) {
      
      getDemandList(id).then(response => {
        if (response.code == 200) {
          this.demandList = response.data
					this.demandList.forEach(item => {
						if (item.pic_count === 0) {
              item.content = `${item.title}；视频问诊次数*${item.video_count}；¥${item.price}`
						} else if (item.video_count === 0) {
              item.content = `${item.title}；图文问诊次数*${item.pic_count}；¥${item.price}`
						} else {
              item.content = `${item.title}；视频问诊次数*${item.video_count}；图文问诊次数*${item.pic_count}；¥${item.price}`
						}
					})
				} else {
          this.$message({
            type: 'error',
            message: response.msg
          })
				}
        this.listLoading = false
			}).catch(error => {
        console.log(error)
        this.listLoading = false
        this.$message({
          type: 'error',
          message: '获取失败!'
        });
			})
		},
    _postPatientSendDemand(id, data) {
		console.log(id,data)
      this.listLoading = true
      postPatientSendDemand(id, data).then(response => {
        if (response.code == 200) {
          this.$message({
            message: '发放成功',
            type: 'success'
          })
					this.getList()
					
				} else {
          this.$message({
            message: response.msg,
            type: 'error'
          })
				}
        this.handleConfirm()
        this.listLoading = false
			}).catch(error => {
        console.log(error)
        this.$message({
          message: '网络问题',
          type: 'success'
        })
			})
		},
    handleDoctorChangeSelect(value) {
			this._getDemandList(value)
			this.ruleForm.doctor_id = value
		},
    handleHealthPackageChangeSelect(value) {
			this.ruleForm.demand_id = value
		},
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this._postPatientSendDemand(this.patientInfo.id, this.ruleForm)
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    handleConfirm () {
      this.resetForm('ruleForm')
      this.$emit('update:isActiveSendHealthPackageVisible', false)
    },
    handleCancel (e) {
      this.resetForm('ruleForm')
      this.$emit('update:isActiveSendHealthPackageVisible', false)
    },
	}
}
</script>

<style scoped lang="scss">
	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	/deep/ .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	/deep/ .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	
	/*弹出框表单配置*/
	/deep/ .el-form-item__label {
		padding-bottom: 0;
	}
	
	/deep/ .el-form-item__content {
		width: 280px;
	}
	
	.from-item-second {
		margin-left: 0;
		margin-right: 20px;
	}
	
	/deep/ .from-item-third {
		.el-form-item__content {
			width: 590px;
		}
		.el-select {
			width: 590px !important;
		}
	}
	
	/deep/ .el-cascader {
		width: 280px;
	}
	
	/deep/ .el-select {
		width: 280px !important;
	}
	
	/deep/ .el-date-editor {
		width: 280px !important;
	}
	
	/*select 宽度配置*/
	/deep/ .el-select {
		width: 85px;
	}
	
	/deep/.el-dialog__footer{
		margin-top: 60px;
	}
</style>
