<template>
	<div class="">
		<CDMSelectionOfTheService
			ref="CDMSelectionOfTheService"
			@getList="getList"
		/>
		<div class=".gg-container-1">
			<div class="search-container">
				<div class="search-container-fn-input">
					<label>客户查找：</label>
					<el-input
						style="width:160px"
						size="mini"
						placeholder="请输入客户姓名/手机号"
						prefix-icon="el-icon-search"
						v-model="searchParams.keywords"
						clearable
						@change="getList('restPage')"
					>
					</el-input>
				</div>
		
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px;margin-left: 5px;
														background-color: #2362FB; border-color: #2362FB"
					@click="getList('restPage')"
				>
					<span>查找</span>
				</el-button>
			
				
				<div class="search-container-fn-input" v-if="adminId == 1">
					<label>商户列表：</label>
					<el-select
						style="width:160px"
						v-model="searchParams.agent_id"
						placeholder="请选择绑定状态"
						size="mini"
						clearable
						@change="getList('restPage')"
					>
						<template slot="prefix">
							<i
								class="el-icon-search"
								style="width: 25px; line-height: 32px;"
							></i>
						</template>
						<el-option
							v-for="item in merchantOptions"
							:key="item.id"
							:label="item.merchant_name"
							:value="item.id"
						>
						</el-option>
					</el-select>
				</div>
				
				<div style="margin-left: auto; display: flex; justify-content: space-around">
					
					<div class="search-container-fn-input"
							 v-if="operateList.indexOf('update_table') > -1"
					>
						<el-button size="mini" type="primary" icon="el-icon-edit-outline"
											 style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
											 @click="handleEditTableHead">编辑表头
						</el-button>
					</div>
					
					<div class="search-container-fn-input"
							 v-if="operateList.indexOf('reset_query') > -1"
					>
						<el-button size="mini" type="primary" icon="el-icon-refresh"
											 style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
											 @click="handleResetSearch">重置查找
						</el-button>
					</div>
					
					
					<div class="search-container-fn-input"
							 v-if="operateList.indexOf('send_interest') > -1"
					>
						<el-button
							size="mini"
							type="success"
							style="margin-left: 5px;padding: 6px 7px 7px 8px;background-color: #FCC810; border-color: #FCC810"
							@click="handleSendHealthPackage()"
						>
							<i class="iconfont icon-wodetaocan" style="font-size: 12px" />
							<span style="margin-left: 5px;color: #fff">发放权益</span>
						</el-button>
					</div>
					
					<div class="search-container-fn-input"
							 v-if="operateList.indexOf('update') > -1"
					>
						<el-button
							size="mini"
							type="success"
							style="margin-left: 5px;"
							icon="el-icon-edit"
							@click="handleEditCon()"
						>
							<span style="margin-left: 5px;color: #fff">编辑</span>
						</el-button>
					</div>
					
					<div class="search-container-fn-input"
							 v-if="operateList.indexOf('delete') > -1"
					>
						<el-button
							size="mini"
							type="danger"
							style="margin-left: 5px;"
							icon="el-icon-delete"
							@click="handleDel()"
						>
							<span style="margin-left: 5px;color: #fff">删除</span>
						</el-button>
					</div>
					
					<div class="search-container-fn-input"
							 v-if="operateList.indexOf('mul_assign') > -1"
					>
						<el-button
							size="mini"
							type="success"
							style="margin-left: 5px;padding: 6px 7px 7px 8px;background-color: #45ce15; border-color: #45ce15"
							@click="handleDialogFormVisible"
						>
							<i class="el-icon-document" style="font-size: 14px"/>
							<!--<a style="margin-left: 5px; font-size: 12px; " href="file/PatientRecords.xlsx">下载模板</a>-->
							<span style="margin-left: 5px; font-size: 12px; "
							>批量分配</span
							>
						</el-button>
					</div>
					<div class="search-container-fn-input" style="margin-left: 5px;"
							 v-if="operateList.indexOf('export') > -1"
					>
						<el-button
							size="mini"
							type="success"
							style="padding: 6px 7px 7px 8px;background-color: #45ce15; border-color: #45ce15"
							@click="handlePatientExport"
						>
							<i class="el-icon-plus" style="font-size: 14px"/>
							<!--<a style="margin-left: 5px; font-size: 12px; " href="file/PatientRecords.xlsx">下载模板</a>-->
							<span style="margin-left: 5px; font-size: 12px; "
							>导出</span
							>
						</el-button>
					</div>
					<div class="search-container-fn-input" style="margin-left:5px"
							 v-if="operateList.indexOf('import') > -1"
					>
						<CDMImportTheClues ref="CDMImportTheClues" @getList="getList"/>
					</div>
					<div class="search-container-fn-input" style="margin-left: 5px;"
							 v-if="operateList.indexOf('import') > -1"
					>
						<el-button
							size="mini"
							type="success"
							style="padding: 6px 7px 7px 8px;background-color: #FCC810; border-color: #FCC810"
							@click="handlerDownloadTemplate"
						>
							<i class="el-icon-download" style="font-size: 14px"/>
							<!--<a style="margin-left: 5px; font-size: 12px; " href="file/PatientRecords.xlsx">下载模板</a>-->
							<span style="margin-left: 5px; font-size: 12px; ">下载模板</span>
						</el-button>
					</div>
					<div class="search-container-fn-input" style="margin-left: 0px" v-if="adminId == 1">
						<upload-excel-component
							:on-success="handleSuccess"
							:before-upload="beforeUpload"
						/>
					</div>
				
				</div>
			</div>
		</div>
		
		
		<!----------------表格---------------->
		<el-table
			border
			size="mini"
			v-loading="listLoading || listLoadingDoctor"
			:header-cell-style="{'text-align':'center'}"
			:data="tableData"
			height="625"
			@selection-change="handleSelectionChange"
			style="width: 100%;z-index:0">
			<el-table-column
				type="selection"
				width="55"
				align="center"
			/>
			<el-table-column
				type="index"
				align="center"
				width="50">
			</el-table-column>
			<template
				v-for="(column,index) in tableHead"
			>
				<el-table-column
					:prop="column.column_prop"
					:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
					:width="column.width ? column.width : '' "
					:key="index"
					align="center"
					v-if="column.field_type === 'textBtn' && column.visible === true "
					show-overflow-tooltip
				>
					<!--<template #header>
						<el-popover placement="bottom" title="" width="230" trigger="hover">
							<div slot="reference" class="search-header">
								<span class="search-title">{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
								<i style="margin-left: 5px" class="search-icon el-icon-search"></i>
							</div>
							<el-input
									size="mini"
									placeholder=""
									prefix-icon="el-icon-search"
									v-model="searchParams.buy_count"
									clearable
									@change="getList('restPage')"
								>
								</el-input>
						</el-popover>
					</template>-->
					<template slot-scope="scope">
						<a style="font-size: 12px; color: #2379fb"
							 @click.prevent="handlePatientDetail(scope.row)">
							{{scope.row[column.column_prop]}}
						</a>
					</template>
				</el-table-column>
				
				<el-table-column
					:prop="column.column_prop"
					:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
					:width="column.width ? column.width : '' "
					:key="index"
					align="center"
					v-else-if="column.field_type === 'select' && column.visible === true "
					show-overflow-tooltip
				>
					<template #header>
						<el-popover placement="bottom" title="" width="230" trigger="click">
							<div slot="reference" class="search-header">
								<span class="search-title">{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
								<i style="margin-left: 3px;font-weight: bolder; font-size: 20px"
									 class="el-icon-search"
									 :class="{isSearch: searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch,
									  'el-icon-zoom-in': searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch}"
								/>
							</div>
							<el-select
								v-if="'doctor_id' == column.column_prop"
								v-model="searchParams.bind_status"
								placeholder="请选择绑定状态"
								size="mini"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.bind_status)"
							>
								<template slot="prefix">
									<i
										class="el-icon-search"
										style="width: 25px; line-height: 32px;"
									></i>
								</template>
								<el-option
									v-for="item in bindStatusOptions"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								>
								</el-option>
							</el-select>
						</el-popover>
					</template>
					<template slot-scope="scope">
						<div
							style="display: flex;justify-content: space-around;align-items: center"
						>
							<el-select
								v-if="scope.row.doctor_id > 0"
								v-model="scope.row.doctor_id"
								style="width: 180px"
								size="mini"
								:disabled="scope.row.doctor_id > 0"
								filterable
								placeholder="请输入医生姓名"
								@change="handleDoctorSelect(scope.$index, scope.row)"
							>
								<el-option
									v-for="item in doctorList"
									:key="item.id"
									:label="item.doctor_name"
									:value="item.id"
								>
								</el-option>
							</el-select>
							<el-select
								v-else
								v-model="doctorSelect[scope.$index]"
								style="width: 180px"
								size="mini"
								:disabled="scope.row.doctor_id > 0"
								filterable
								placeholder="请输入医生姓名"
								@change="handleDoctorSelect(scope.$index, scope.row)"
							>
								<el-option
									v-for="item in doctorList"
									:key="item.id"
									:label="item.doctor_name"
									:value="item.id"
								>
								</el-option>
							</el-select>
							<el-button
								:disabled="scope.row.doctor_id > 0"
								size="mini"
								type="primary"
								icon="el-icon-connection"
								style="margin-right: 10px; padding: 7px 8px;margin-left: 5px;
				             background-color: #2362FB; border-color: #2362FB"
								@click="handleBindingDoctor(scope.$index, scope.row)"
							>绑定
							</el-button>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column
					:prop="column.column_prop"
					:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
					:width="column.width ? column.width : '' "
					:key="index"
					align="center"
					v-else-if="column.field_type === 'imageBtn' && column.visible === true "
					show-overflow-tooltip
				>
					<template slot-scope="scope">
						<div v-if="scope.row[column.column_prop].length>0" style="font-size: 12px; color: #2379fb"
								 @click="handleShowImage(scope.row[column.column_prop],  scope.row[column.column_prop][0])">
							<a>点击查看图片</a>
							<el-image
								style="width: 0px; height: 0px"
								:ref="scope.row[column.column_prop][0]"
								:src="imageUrl"
								:preview-src-list="scope.row[column.column_prop]"
								:z-index="9999">
							</el-image>
						</div>
						<div v-else>
							暂无
						</div>
					</template>
				</el-table-column>
				
				<el-table-column
					:sortable="tableHeadSortable.includes(column.column_prop)"
					:prop="column.column_prop"
					:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
					:width="column.width ? column.width : '' "
					:key="index"
					align="center"
					v-else-if="column.visible === true"
					show-overflow-tooltip
				>
					<template #header>
						<el-popover placement="bottom" title="" min-width="160" trigger="click"
												v-if="searchTableHead.filter( item => item.name == column.column_prop).length > 0">
							<span slot="reference" class="search-header">
								<span
									class="search-title"
								>{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
								<i style="margin-left: 3px;font-weight: bolder; font-size: 20px"
									 class="el-icon-search"
									 :class="{isSearch: searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch,
									  'el-icon-zoom-in': searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch}"
								/>
							</span>
							
							
							<!--<el-input-->
							<!--	v-if="'order_count' == column.column_prop"-->
							<!--	size="mini"-->
							<!--	placeholder=""-->
							<!--	prefix-icon="el-icon-search"-->
							<!--	v-model="searchParams.buy_count"-->
							<!--	clearable-->
							<!--	@change="handleSearch(column.column_prop ,searchParams.buy_count)"-->
							<!--&gt;-->
							<!--</el-input>-->
							
							<el-select
								v-if="'is_health' == column.column_prop"
								v-model="searchParams.is_health"
								placeholder="请选择绑定状态"
								size="mini"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.is_health)"
							>
								<template slot="prefix">
									<i
										class="el-icon-search"
										style="width: 25px; line-height: 32px;"
									></i>
								</template>
								<el-option
									v-for="item in healthStatusOptions"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								>
								</el-option>
							</el-select>
							
							
							<!--<el-select-->
							<!--	v-else-if="'visit_level' == column.column_prop"-->
							<!--	style="width:200px"-->
							<!--	v-model="searchParams.visit_level"-->
							<!--	placeholder="请选择"-->
							<!--	size="mini"-->
							<!--	clearable-->
							<!--	@change="handleSearch(column.column_prop ,searchParams.visit_level)"-->
							<!--&gt;-->
							<!--	<template slot="prefix">-->
							<!--		<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>-->
							<!--	</template>-->
							<!--	<el-option-->
							<!--		v-for="item in optionsVisitLevel"-->
							<!--		:key="item.short_name"-->
							<!--		:label="item.short_name"-->
							<!--		:value="item.short_name">-->
							<!--	</el-option>-->
							<!--</el-select>-->
							
						
							<el-date-picker
								v-else-if="'order_at' == column.column_prop"
								v-model="searchParams.order_at"
								:default-time="['00:00:00', '23:59:59']"
								type="daterange"
								size="mini"
								value-format="yyyy-MM-dd HH:mm:ss "
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								@change="handleSearch(column.column_prop ,searchParams.order_at)">
							</el-date-picker>
							
							
							<el-date-picker
								v-else-if="'created_at' == column.column_prop"
								v-model="searchParams.created_at"
								:default-time="['00:00:00', '23:59:59']"
								type="daterange"
								size="mini"
								value-format="yyyy-MM-dd HH:mm:ss "
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								@change="handleSearch(column.column_prop ,searchParams.created_at)">
							</el-date-picker>
							
							<el-date-picker
								v-else-if="'expired_at' == column.column_prop"
								v-model="searchParams.expired_at"
								:default-time="['00:00:00', '23:59:59']"
								type="daterange"
								size="mini"
								value-format="yyyy-MM-dd HH:mm:ss "
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								@change="handleSearch(column.column_prop ,searchParams.expired_at)">
							</el-date-picker>
							
							
							<el-date-picker
								v-else-if="'end_visit_at' == column.column_prop"
								v-model="searchParams.end_visit_at"
								:default-time="['00:00:00', '23:59:59']"
								type="daterange"
								size="mini"
								value-format="yyyy-MM-dd HH:mm:ss "
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								@change="handleSearch(column.column_prop ,searchParams.end_visit_at)">
							</el-date-picker>
							
							<el-select
								v-else-if="'staff_name' == column.column_prop"
								style="width:200px"
								v-model="searchParams.staff_id"
								placeholder="请选择"
								size="mini"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.staff_id)"
							>
								<template slot="prefix">
									<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
								</template>
								<el-option
									v-for="item in staffOptions"
									:key="item.id"
									:label="item.staff_name"
									:value="item.id">
								</el-option>
							</el-select>
							
							<!--<el-date-picker-->
							<!--	v-else-if="'expired_at' == column.column_prop"-->
							<!--	v-model="choiceDateExpire"-->
							<!--	:default-time="['00:00:00', '23:59:59']"-->
							<!--	type="daterange"-->
							<!--	size="mini"-->
							<!--	value-format="yyyy-MM-dd HH-mm-ss "-->
							<!--	range-separator="至"-->
							<!--	start-placeholder="开始日期"-->
							<!--	end-placeholder="结束日期"-->
							<!--	@change="handleExpireChangeDate">-->
							<!--</el-date-picker>-->
						
						
						</el-popover>
						<span v-else>{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
					</template>
					<template slot-scope="scope">
						
						
						<span
							v-if="column.column_prop === 'buy_status'">{{scope.row[column.column_prop] === 1 ? '未购' : '已购' }}
						</span>
						
						<span
							v-else-if="column.column_prop === 'is_health'">{{scope.row[column.column_prop] == true ? "已填写" : "未填写"  }}
						</span>
						
						<span v-else-if="column.column_prop === 'use_status'">
								{{scope.row[column.column_prop] | useStatusFormat }}
							</span>
						<span v-else-if="column.column_prop === 'is_wx'">
								{{scope.row[column.column_prop] == '0' ? '否' : '是'}}
							</span>
						<span v-else-if="column.column_prop === 'supervise_status'">
								{{scope.row[column.column_prop] | formatSuperviseStatus}}
							</span>
						<span v-else>{{scope.row[column.column_prop]}}</span>
					</template>
				</el-table-column>
			</template>
		</el-table>
		
		
		<!----------------分页---------------->
		<pagination
			v-show="total > 0"
			:total="total"
			:page.sync="listQuery.page"
			:limit.sync="listQuery.limit"
			@pagination="getList"
		/>
		
		
		<el-drawer
			v-loading="listLoading"
			:before-close="handleCloseDrawer"
			@closeDrawer="handleCloseDrawer"
			title=""
			size="76%"
			:visible.sync="drawerVisible"
			:with-header="false"
		>
			<CDMPatientManagementDetails
				@closeDrawer="closeDrawer"
				@getList="getList"
				:patientInfo="patientInfo"
				v-if="drawerVisible"
				ref="CDMPatientManagementDetails"
			>
			</CDMPatientManagementDetails>
		</el-drawer>
		
		<CDMPatientManageDialogs
			:isActiveSendHealthPackageVisible.sync="isActiveSendHealthPackageVisible"
			v-if="isActiveSendHealthPackageVisible"
			:patientInfo="patientInfo"
		>
		</CDMPatientManageDialogs>
		<el-dialog :visible.sync="dialogVisible" title="导入失败明细">
			<div style="margin-bottom: 15px;">
				导入成功条数
				<span style="color:green;">{{ dialogData.success }}</span>
				条,导入失败条数
				<span style="color: red;">{{ dialogData.fail }}</span> 条
			</div>
			<el-table :data="fail_info" style="max-height: 500px;overflow: auto;">
				<el-table-column
					prop="real_name"
					label="姓名"
					align="center"
				></el-table-column>
				<el-table-column
					prop="age"
					label="年龄"
					align="center"
					width="70"
				></el-table-column>
				<el-table-column
					prop="gender"
					label="性别"
					align="center"
					width="70"
				></el-table-column>
				<el-table-column
					prop="mobile"
					label="电话"
					align="center"
				></el-table-column>
				<el-table-column
					prop="desc"
					label="病情描述"
					align="center"
				></el-table-column>
				<el-table-column
					prop="ad_expand"
					label="客情工号"
					align="center"
				></el-table-column>
				<el-table-column
					prop="error"
					label="失败原因"
					align="center"
				></el-table-column>
			</el-table>
		</el-dialog>
		
		
		<!--编辑表头-->
		<editTableHead :isActiveEditTableHead.sync="isActiveEditTableHead"
															 @_getAdminFieldIndex="_getAdminFieldIndex"
															 v-if="isActiveEditTableHead"
      :table_type="table_type"
    >
		
		</editTableHead>
	
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import UploadExcelComponent from "@/components/UploadExcel";
import CDMPatientManagementDetails from "./components/CDMPatientManagementDetails";
import CDMPatientManageDialogs from "./components/CDMPatientManageDialogs";
import CDMSelectionOfTheService from "./components/CDMSelectionOfTheService";
import CDMImportTheClues from "./components/CDMImportTheClues";
import {
  getPatientList,
  postPatientImport,
  postPatientSyncContract,
  getMerchants,
  getStaff,
  saveContract,
  getPatientInfo,
  DeldelContractM,
  getPatientExport
} from "@/api/chronicDisease/patientManage";
import {getAdminFieldIndex} from '@/api/ggkq';
import {getStaffList} from '@/api/patientManagement';
import {getButlerDoctorList, postButlerBind} from "@/api/cdm/cdm-housekeeper";
import {mapGetters, mapState} from "vuex";
import {getVisitLevel} from '@/api/ggkq';
import {examineExportList} from '@/api/pharmacy/pharmacy';
import editTableHead from '@/components/editTableHead';

export default {
  name: "CDMPatientManage",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CDMImportTheClues,
    Pagination,
    CDMPatientManagementDetails,
    CDMPatientManageDialogs,
    UploadExcelComponent,
    // eslint-disable-next-line vue/no-unused-components
    CDMSelectionOfTheService,
    editTableHead

  },

  async created () {
    console.log(this.$route.path)
    console.log(this.operates)
    this.operates.forEach(operate => {
      if(operate[this.$route.path]) {
        console.log(operate[this.$route.path].split(','))
        this.operateList.push(...operate[this.$route.path].split(','))
      }
    })
    await this._getStaffList ()
    await this._getAdminFieldIndex()
    await this.getVisitLevelList()
    this.getList();
    this._getDoctorsList();
    this._getMerchants();
  },
  // updated(){
  //   console.log(this.$refs)
  // },
  computed: {
    ...mapGetters(["adminId", "agentId"]),
    ...mapState({
      is_admin: state => state.user.is_admin,
      operates: state => state.user.operates,
      table_options: state => state.user.table_options,
    }),
  },
  filters: {
    formatIllDuration (value) {
      const illDurationList = [
        {
          name: "一周内",
          value: 1,
        },
        {
          name: "一月内",
          value: 2,
        },
        {
          name: "半年内",
          value: 3,
        },
        {
          name: "一年以内",
          value: 4,
        },
        {
          name: "一到三年",
          value: 5,
        },
        {
          name: "三到五年",
          value: 6,
        },
        {
          name: "五到十年",
          value: 7,
        },
        {
          name: "十年以上",
          value: 8,
        },
      ];
      let item = illDurationList.filter((item) => item.value == value);
      return (item[0] && item[0].name) || "";
    },
  },
  data () {
    return {
      table_type:'CDMPatientManage',
      searchTableHead: [
        {
          name: 'doctor_id', // ?
          isSearch: false
        },
        {
          name: 'is_health',
          isSearch: false
        },
        {
          name: 'agent_id',
          isSearch: false
        },
        {
          name: 'staff_id',
          isSearch: false
        },
        {
          name: 'expired_at',
          isSearch: false
        },
        {
          name: 'end_visit_at',
          isSearch: false
        },
        {
          name: 'created_at',
          isSearch: false
        },
        {
          name: 'order_at',
          isSearch: false
        },
        {
          name: 'staff_name',
          isSearch: false
        },
      ],
      tableHeadSortable: [],
      tableHead: [],
      isActiveEditTableHead: false,
      show: true,
      staff: [],
      dialogData: {},
      dialogVisible: false,
      dialogFormVisible: false,
      dialogEditVisible: false,
      contract: {},
      fail_info: [],
      avatarSrc: require("@/assets/avatar.png"),
      searchParams: {
        name: "",
        serviceInfo: "",
        keywords: "",
        bind_status: "",
        created_at: "",
        agent_id: 1,
      },
      created_at: "",
      bindStatusOptions: [
        {
          value: 1,
          label: "已绑定",
        },
        {
          value: 0,
          label: "未绑定",
        },
      ],
      merchantOptions: [],
      staffOptions: [],
      isBuyOptions: [
        {
          label: "已购",
          value: 2
        },
        {
          label: "未购",
          value: 1
        },
      ],
      healthStatusOptions: [
        {
          value: "true",
          label: "已填写",
        },
        {
          value: "false",
          label: "未填写",
        },
      ],
      rules: {
        real_name: [{ required: true, message: "请填写姓名", trigger: "blur" }],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
        age: [{ required: true, message: "请填写年龄", trigger: "blur" }],
        mobile: [{ required: true, message: "请填写手机号", trigger: "blur" }],
        ad_expand: [{ required: true, message: "请选择客服", trigger: "blur" }],
      },
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      total: 0,
      listLoading: false,
      listLoadingDoctor: false,
      tableData: [],
      tableHeader: [],
      isActiveSendHealthPackageVisible: false,
      drawerVisible: false,
      patientInfo: [],
      doctorList: [],
      doctorSelect: {},
      multipleSelection: [],
      multipleSelectionPatient: [],
      operateList: [],
    };
  },
  methods: {

    handleSearch (prop, value) {
      console.log(prop)
			console.log(value)
      this.searchTableHead.forEach(item => {
        if (item.name == prop) {
          if (value == '' || value == null  ) {
            item.isSearch = false
          } else {
            item.isSearch = true
          }
        }
      })
      this.getList('restPage')
    },
    handleResetSearch () {
      this.searchParams = {
        name: "",
        serviceInfo: "",
        keywords: "",
        bind_status: "",
        created_at: "",
        agent_id: 1,
      }
      this.searchTableHead.forEach(item => {
        item.isSearch = false
      })
      this.choiceDateCreateRecord = []
      this.choiceDateExpire = []
      this.getList('restPage')
    },
    async _getAdminFieldIndex () {
      // try {
      //   this.listLoading = true
      //   var params = {
      //     type: 'CDMPatientManage'
      //   }
      //   const res = await getAdminFieldIndex(params)
        this.tableHead = this.table_options.CDMPatientManage
      //   console.log(this.tableHead)
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false
      // }

    },

    handleEditTableHead() {
      this.isActiveEditTableHead = true
    },
		
    //删除按钮
    handleDel () {
      if (this.multipleSelection.length > 1) {
        this.$message({
          type: 'error',
          message: '只能单个删除!'
        });
        return
      } else if(this.multipleSelection.length == 0) {
        this.$message({
          type: 'error',
          message: '请选择!'
        });
        return
      }
      //确定删除框
      console.log(this.multipleSelectionPatient[0])
      this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //确定删除
        DeldelContractM(this.multipleSelectionPatient[0].id).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //客服选择
    handleDialogFormVisible () {
      console.log(this.multipleSelection);
      if (this.multipleSelection.length) {
        this.dialogFormVisible = true;
        //将this.dialogFormVisible传递给子组件
        this.$refs.CDMSelectionOfTheService.dialogFormVisible = this.dialogFormVisible;
      } else {
        this.$message({
          message: "请选择批量分配的档案",
          type: "warning",
        });
      }
    },
    onSubmit () {
      this.listLoading = true;
      saveContract(this.contract.id, this.contract).then((res) => {
        console.log("asdasdasdasd", this.contract.id, this.contract);
        this.listLoading = false;
        if (res.code == 200) {
          this.dialogEditVisible = false;
          this.getList();
          return this.$message.success("修改成功！");
        } else {
          return this.$message.error(res.msg);
        }
      });
    },

    _getStaff () {
      getStaff(this.contract.id).then((res) => {
        if (200 == res.code) {
          this.staff = res.data;
          this.staff = this.staff.map((item) => {
            item.staff_name = item.staff_name.replace(/-\d+$/, "-");
            return item;
          });
          // res.data = res.data.map(item=>{
          //   return {
          //     value:item.id,
          //     label:item.staff_name,
          //     expand:item.ad_expand,
          //     checked:false,
          //   }
          // })
          //   this.$refs.CDMSelectionOfTheService.staff = res.data;
          //   console.log(this.$refs.CDMSelectionOfTheService.staff);
        }
      });
    },
    remoteMethod (query) {
      if (query !== "") {
        this.listLoading = true;
        getStaff({ keywords: query }).then((res) => {
          this.listLoading = false;
          if (200 == res.code) {
            this.staff = res.data;
          }
        });
      } else {
        this.options = [];
      }
    },
    handleEditCon () {
      this.handlePatientDetail();
    },
    _getMerchants () {
      getMerchants().then((response) => {
        if (response.code == 200) {
          this.merchantOptions = response.data;
        } else {
          this.$message({
            type: "error",
            message: response.msg,
          });
        }
      });
    },

    getList (rest) {
      this.doctorSelect = {};
      if (rest == "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      if (!this.timeValue) {
        this.searchParams.start_at = "";
        this.searchParams.end_at = "";
      } else {
        this.searchParams.start_at = this.timeValue[0];
        this.searchParams.end_at = this.timeValue[1];
      }

      let params = {
        ...this.searchParams,
      };
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      getPatientList(params)
        .then(async (response) => {
          this.$refs.CDMSelectionOfTheService.agent_id = params.agent_id;
          // this.$refs.CDMImportTheClues.agent_id = params.agent_id;
          //存本地
          localStorage.setItem("agent_id", params.agent_id);
          console.log(this.$refs, 1111);
          let data = response;
          if (data.code == 200) {
            console.log(data.data, "aaaa");
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
            console.log(this.tableData);
          }
          await this.handleGetStaffList()
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    async getVisitLevelList () {
      // 1 未购 2 在用 3 预停 4 停用
      var result = await getVisitLevel(
        { buy_status: 2 }
      )
      console.log(result)
      this.optionsVisitLevel = result.data
      this.optionsVisitLevel.forEach(item => {
        item.short_name_detail = item.short_name + ' ' + item.name + ' ' + item.next_content
      })
    },
    async handleGetStaffList () {
      var result = await getStaffList({ agent_id: this.searchParams.agent_id })
      console.log(result)
      this.staffOptions = result.data
    },
    _postPatientSyncContract (data) {
      this.listLoading = true;
      postPatientSyncContract(data)
        .then((response) => {
          if (response.code == 200) {
            this.$message({
              type: "success",
              message: "同步成功",
            });
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: response.msg,
            });
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            type: "error",
            message: "网络问题",
          });
          this.listLoading = false;
        });
    },
    _postPatientImport (data) {
      this.listLoading = true;
      postPatientImport(data)
        .then((response) => {
          console.log("response", response);
          if (response.code == 200) {
            if (response.data.fail.length == "0") {
              this.$message({
                type: "success",
                message: `导入成功条数${response.data.success}`,
              });
            } else {
              this.dialogVisible = true;
              response.data.fail_info.forEach((item) => {
                if (item.gender == "1") {
                  item.gender = "男";
                } else {
                  item.gender = "女";
                }
              });
              this.dialogData = response.data;
              this.fail_info = response.data.fail_info;
              console.log("this.fail_info", this.fail_info);
            }
            // this.$message({
            // 	type: 'warning',
            // 	message: `导入成功条数 ${response.data.success},导入失败条数 ${response.data.fail}`
            // })
          } else {
            this.$message({
              type: "error",
              message: response.message,
              duration: 5000,
            });
          }
          this.listLoading = false;
          this.getList();
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            type: "error",
            message: "网络错误",
          });
          this.listLoading = false;
        });
    },
    // isSelectable(row) {
    //   // console.log('row11111111', row)
    //   return row.sync_status !== 1;
    // },
    _getDoctorsList (rest) {
      if (rest === "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoadingDoctor = true;
      let params = {
        ...this.searchParams,
      };
      getButlerDoctorList(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            this.doctorList = data.data;
          }
          this.listLoadingDoctor = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoadingDoctor = false;
        });
    },
    async _getStaffList () {
      var params = {
        agent_id: this.agentId
      }
      var result = await getStaffList(params)
      console.log(result)
      this.staffOptions = result.data
    },
    _postButlerBind (data) {
      this.listLoading = true;
      postButlerBind(data)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            this.$message({
              showClose: true,
              message: "绑定成功",
              type: "success",
            });
            this.getList();
          } else {
            this.$message({
              showClose: true,
              message: data.message || data.msg,
              type: "error",
            });
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            showClose: true,
            message: "网络错误",
            type: "success",
          });
          this.listLoading = false;
        });
    },
    handleDoctorSelect (index, row) {
      console.log(row.id); // 患者
      console.log(this.doctorSelect);
    },
    handleBindingDoctor (index, row) {
      console.log(this.doctorSelect[index], 0);
      console.log(row.id, 0);
      const data = {
        con_id: row.id.toString(),
        doctor_id: this.doctorSelect[index].toString(),
      };
      this._postButlerBind(data);
    },
    handlerDownloadTemplate () {
      console.log(this.$refs.download);
      let a = document.createElement("a");
      a.href = `/file/PatientRecords.xlsx`;
      a.download = "档案管理模板.xlsx";
      a.click();
    },
    handleSelectionChange (val) {
      console.log(val, "val");
      //去判断按钮的显示与隐藏
      if (val.length > 0) {
        this.show = true;
      } else {
        this.show = false;
      }
      this.multipleSelection = [];
      this.multipleSelectionPatient = []
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPatient.push(item);
      });
      //将this.multipleSelection传给子组件
      // console.log(this.$refs);
      this.$refs.CDMSelectionOfTheService.multipleSelection = this.multipleSelection;
    },
    beforeUpload (file) {
      // excel上传前
      console.log(file);
      const isLt1M = file.size / 1024 / 1024 < 3;
      console.log(isLt1M);
      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "请传入excel大小不要超过3M.",
        type: "warning",
      });
      return false;
    },
    handleSuccess ({ results, header }) {
      // excel成功的结果
      console.log(results);
      let data = {
        file: results,
      };
      console.log(data);
      this._postPatientImport(data);
      this.getList();
    },
    handlerBatchUpload () {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: "请选择需要同步的选项",
          type: "error",
        });
      } else {
        let data = {
          ids: this.multipleSelection,
        };
        this._postPatientSyncContract(data);
      }
    },
    handleSendHealthPackage () {
      if (this.multipleSelection.length > 1) {
        this.$message({
          type: 'error',
          message: '只能单个发放!'
        });
        return
			} else if(this.multipleSelection.length == 0) {
        this.$message({
          type: 'error',
          message: '请选择!'
        });
        return
			}
			console.log(this.multipleSelectionPatient[0])
      this.patientInfo = this.multipleSelectionPatient[0];
      this.isActiveSendHealthPackageVisible = true;
      
    },
    handlePatientDetail () {
      if (this.multipleSelection.length > 1) {
        this.$message({
          type: 'error',
          message: '只能单个编辑!'
        });
        return
      } else if(this.multipleSelection.length == 0) {
        this.$message({
          type: 'error',
          message: '请选择!'
        });
        return
      }
      this.drawerVisible = true;
      getPatientInfo(this.multipleSelectionPatient[0].id).then((res) => {
        if (res.code == 200) {
          this.patientInfo = res.data;
          console.log(this.patientInfo, "22132312");
          // this.patientInfo = res.data;
        }
      });
    },
    handlePatientExport () {
      let params = {
        ...this.searchParams,
      };
      console.log(params);
      this.listLoading = true;
      getPatientExport(params)
        .then((response) => {
          let data = response;
          if (data) {
            const xlsx = "application/vnd.ms-excel";
            const blob = new Blob([data], {
              type: xlsx
            });
            const a = document.createElement("a"); // 转换完成，创建一个a标签用于下载
            a.download = "患者档案.xlsx";
            a.href = window.URL.createObjectURL(blob);
            a.click();
            a.remove();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        }).finally(() => {
        this.listLoading = false;
      });
    },
    handleCloseDrawer () {
      this.drawerVisible = false;
    },
    closeDrawer (e) {
      this.drawerVisible = e;
    },
  },
};
</script>

<style scoped lang="scss">
	.header {
		display: flex;
		align-items: center;
		height: 26px;
		margin-bottom: 10px;
		
		i {
			width: 25px;
			height: 25px;
			background-color: #2632fb;
			color: white;
			line-height: 25px;
			text-align: center;
			margin-right: 8px;
			border-radius: 2px;
		}
	}
	
	/*drawer 抽屉高低配置*/
	/deep/ .el-drawer {
		bottom: 0 !important;
	}
	
	/deep/ .el-drawer__header {
		padding: 0;
		margin: 0;
	}
	
	/deep/ .el-drawer.rtl {
		overflow: visible;
		height: 91vh;
		bottom: 0;
		margin-top: 9vh;
		position: absolute;
	}
	
	.btn-box {
		width: 30vw;
		// height:80px;
		display: flex;
		flex-direction: row;
		justify-content: end;
		flex-wrap: wrap;
		align-items: flex-end;
	}
	
	.isSearch{
		color: #F56C6C;
	}
	.search-icon {
		color: #F56C6C;
	}

</style>
