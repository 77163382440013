<!-- eslint-disable vue/valid-v-else -->
<!-- eslint-disable no-cond-assign -->
<template>
  <div class="gg-container">
    <el-button
      type="danger"
      @click="closeDrawer"
      style="position: absolute; display: flex; justify-content: center; align-items: center; border: 0; border-radius: 4px 0 0 4px; top: 140px; left: -40px;width: 40px; height: 40px;"
    >
      <i class="iconfont icon-cha" style="font-size: 24px"></i>
    </el-button>
    <!-- <div class="caption-container">
      <p class="caption-title">患者信息</p>
      <div class="caption-wrapper">
        <div class="caption-avatar">
          <el-avatar
            :size="60"
            :src="patientInfo.ad_friend_photo || circleUrl"
          ></el-avatar>
        </div>
        <div class="caption-info">
          <div class="caption-info-up">
            <span class="name">{{ patientInfo.real_name }}</span>
            <span class="mobile">{{ patientInfo.mobile }}</span>
          </div>
          <div class="caption-info-down">
            <div class="gender">
              <div class="nv" v-if="2 == patientInfo.gender">
                <i class="iconfont icon-nv"></i>
              </div>
              <div class="nan" v-else-if="1 == patientInfo.gender">
                <i class="iconfont icon-nan"></i>
              </div>
            </div>
            <div class="intro">
            	<i class="iconfont icon-yishichangjing intro-icon"></i>
            	<span class="intro-title">{{patientDetail.terminal_name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!--tabs-->
    <div class="gg-tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="first" v-loading="listLoading">
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple" ref="patientName">
                <span style="color:red">*</span>姓名
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                <span v-if="flag">{{ patientInfo.real_name }}</span>
                <el-input
                  v-model="patientInfo.real_name"
                  placeholder="请输入内容"
                  v-else
                ></el-input>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content bg-purple">
                <span style="color:red">*</span>性别
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                <span v-if="flag">
                  {{
                    patientInfo.gender == 1
                      ? "男"
                      : patientInfo.gender == 2
                      ? "女"
                      : "未知"
                  }}
                </span>
                <div v-else>
                  <el-radio v-model="patientInfo.gender" :label="1"
                    >男</el-radio
                  >
                  <el-radio v-model="patientInfo.gender" :label="2"
                    >女</el-radio
                  >
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                年龄
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                <span v-if="flag">{{ patientInfo.age }}</span>
                <el-input
                  v-model="patientInfo.age"
                  placeholder="请输入内容"
                  v-else
                ></el-input>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content bg-purple">
                <span style="color:red">*</span>电话
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                <span v-if="flag">{{ patientInfo.mobile }}</span>
                <el-input
                  v-model="patientInfo.mobile"
                  placeholder="请输入内容"
                  v-else
                ></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                身份证号
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                <span v-if="flag">{{ patientInfo.patient_ID }}</span>
                <el-input
                  v-model="patientInfo.patient_ID"
                  placeholder="请输入内容"
                  v-else
                ></el-input>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content bg-purple">
                既往史
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                {{ patientInfo.past_medical }}
              </div>
            </el-col>
          </el-row>
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                过敏史
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                {{ patientInfo.allergy_medical }}
              </div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content bg-purple">
                病情描述
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                {{ patientInfo.desc }}
              </div>
            </el-col>
          </el-row>
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                客户地址
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                {{ patientInfo.address_info }}
              </div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content bg-purple">
                顾客来源
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                <span v-if="flag"> {{ patientInfo.from }}</span>
                <el-select
                  v-model="patientInfo.from"
                  placeholder="请选择"
                  @change="changeList"
                  v-else
                >
                  <el-option
                    v-for="item in fromList"
                    :key="item.from_id"
                    :label="item.from"
                    :value="item.from_id"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <div style="margin-bottom:20px"></div>
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                区域
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                {{
								patientInfo.district
                }}
								<!--patientInfo.district == "-"-->
								<!--? "-"-->
								<!--: patientInfo.district.replace(/-/g, "")-->
              </div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content bg-purple">
                客服人员
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                {{ patientInfo.staff_name }}
              </div>
            </el-col>
          </el-row>
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                档案类别
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                <span v-if="flag">
                  {{
                    patientInfo.m_category == 1
                      ? "采线"
                      : patientInfo.m_category == 2
                      ? "运营"
                      : patientInfo.m_category == 3
                      ? "回访"
                      : ""
                  }}</span
                >
                <el-select
                  v-model="patientInfo.m_category"
                  placeholder="请选择"
                  @change="changeListData(patientInfo.m_category)"
                  v-else
                >
                  <el-option
                    v-for="item in recordsSeries"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content bg-purple">
                客服工号
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                {{ patientInfo.ad_expand }}
              </div>
            </el-col>
          </el-row>
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                购买状态
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                <span v-if="flag">
                  {{ patientInfo.buy_status == 2 ? "已购" : "未购" }}</span
                >
                <el-select
                  v-model="patientInfo.buy_status"
                  placeholder="请选择"
                  @change="changeListData1(patientInfo.buy_status)"
                  v-else
                >
                  <el-option
                    v-for="item in buyState"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content bg-purple">
                线上明细
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                <span v-if="flag">{{ patientInfo.order_info }}</span>
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="patientInfo.order_info"
                  maxlength="200"
                  show-word-limit
                  v-else
                >
                </el-input>
              </div>
            </el-col>
          </el-row>
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                回访级别
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                <span v-if="flag">{{ patientInfo.visit_level }}</span>
                <el-select
                  v-model="patientInfo.visit_level"
                  placeholder="请选择"
                  :disabled="disabled"
                  v-else
                >
                  <el-option
                    v-for="item in shortName"
                    :key="item.id"
                    :label="item.short_name"
                    :value="item.short_name"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                到期日期
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                <span v-if="flag">{{ patientInfo.expired_at }}</span>
                <el-date-picker
                  v-else
                  v-model="patientInfo.expired_at"
                  align="right"
                  type="date"
                  :placeholder="patientInfo.expired_at"
                  :picker-options="pickerOptions"
                  @change="changeDate($event)"
                >
                </el-date-picker>
              </div>
            </el-col>
          </el-row>
          <div v-show="determineToCancel" class="bottom_btn" style="">
            <el-button
              size="small"
              @click="handleCancel()"
              style="border-color:#5677fc;color:#5677fc;"
              >取 消</el-button
            >
            <el-button
              size="small"
              style="background-color:#5677fc;color:white;"
              @click="handleNotarize"
              >确 认</el-button
            >
          </div>
        </el-tab-pane>
        <!--				<el-tab-pane label="就诊人列表" name="second">
					<el-table
						v-loading="listLoading"
						:header-cell-style="{'text-align':'center'}"
						:data="tableData"
						stripe
						border
						style="width: 100%">
						<el-table-column
							fixed="left"
							prop="pat_id"
							label="ID"
							align="center">
						</el-table-column>
						<el-table-column
							prop="patient_name"
							label="姓名"
							align="center">
						</el-table-column>
						<el-table-column
							prop="head_portrait"
							label="图像"
							align="center">
							<template slot-scope="scope">
								<el-avatar :size="40" :src="scope.row.head_portrait || circleUrl" ></el-avatar>
							</template>
						</el-table-column>
						<el-table-column
							prop="age"
							label="年龄"
							align="center">
						</el-table-column>
						<el-table-column
							prop="patient_gender"
							label="性别"
							align="center">
							<template slot-scope="scope">
								{{scope.row.patient_gender | genderFormat}}
							</template>
						</el-table-column>
						<el-table-column
							prop="patient_phone"
							label="手机号"
							align="center">
						</el-table-column>
						<el-table-column
							prop="patient_ID"
							label="身份证"
							align="center">
						</el-table-column>
						<el-table-column
							prop="from_name"
							label="来源"
							align="center">
						</el-table-column>
						<el-table-column
							prop="relation_anme"
							label="与本人关系"
							align="center">
						</el-table-column>
						<el-table-column
							prop="past_medical"
							label="既往史"
							align="center">
						</el-table-column>
						<el-table-column
							prop="allergy_medical"
							label="过敏史"
							align="center">
							<template slot-scope="scope">
								<el-tooltip placement="top">
									<div slot="content">{{scope.row.allergy_medical}}<br/>{{scope.row.past_medical}}</div>
									<el-button type="text">{{scope.row.allergy_medical}}</el-button>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column
							fixed="right"
							align="center"
							label="操作">
							<template slot-scope="scope">
								<el-button
									size="mini"
									style="padding: 7px 8px;margin-left: 5px;"
									@click="handleInterest( scope.row)">发放会员权益
								</el-button>
								<el-button
									size="mini"
									type="danger"
									icon="el-icon-delete"
									style="padding: 7px 8px;margin-left: 5px;"
									@click="handleDelete(scope.$index, scope.row)">删除
								</el-button>
								
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>-->
        <el-tab-pane label="日期记录" name="second">
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                建档日期
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                {{ patientInfo.created_at }}
              </div>
            </el-col>
          </el-row>
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                建档方式
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                {{ patientInfo.staff_phone }}
              </div>
            </el-col>
          </el-row>
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                建档人员
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                {{ patientInfo.staff_phone }}
              </div>
            </el-col>
          </el-row>
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                运营分配日期
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                {{ patientInfo.assign_at }}
              </div>
            </el-col>
          </el-row>
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                运营人员
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                {{ patientInfo.staff_name }}
              </div>
            </el-col>
          </el-row>
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                维护分配日期
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                {{ patientInfo.assign_at }}
              </div>
            </el-col>
          </el-row>
          <div style="margin-bottom:50px;"></div>
          <el-row class="patient-detail">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                末回日期
              </div>
            </el-col>
            <el-col :span="10">
              <div class="detail-content grid-content bg-purple-light">
                {{ patientInfo.expired_at }}
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane disabled v-if="editBtn">
          <span slot="label">
            <el-link type="primary" :underline="false" @click="handleEditBtn"
              >编辑</el-link
            >
          </span>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { putUpdate, getVisitLevel } from "@/api/chronicDisease/patientManage";
import { getBasicConfig } from "@/api/rank/tags";
export default {
  name: "CDMPatientManagementDetails",
  props: {
    patientInfo: {
      type: Object,
      required: true,
    },
  },
  //监视m_category 和buy_status的变化,从而来调用接口getVisitLevel
  watch: {
    patientInfo: {
      handler: function(newval, oldval) {
        //当m_category和buy_status发生改变的时候,调用接口
        // console.log(newval.m_category, oldval.m_category, newval.buy_status, oldval.buy_status);
        if (newval.m_category || newval.buy_status) {
          getVisitLevel({
            m_category: newval.m_category,
            buy_status: newval.buy_status,
          }).then((res) => {
            if (res.code == 200) {
              this.shortName = res.data;
            }
          });
        }
      },
      deep: true,
    },
  },
  async created() {
    // console.log("this.patientInfo", this.patientInfo);
  },
  mounted() {
    this.getBasicConfigData();
    // this.getVisitLevelData();
    console.log("this.patientInfo", this.patientInfo);
  },
  data() {
    return {
      short_name: "",
      dataList: {},
      // disabled: false,
      //档案类别
      recordsSeries: [
        { value: 1, label: "采线" },
        { value: 2, label: "运营" },
        { value: 3, label: "回访" },
      ],
      //来源
      fromList: [],
      //购买状态
      buyState: [
        { value: 1, label: "未购" },
        { value: 2, label: "已购" },
      ],
      //确定取消
      determineToCancel: false,
      //编辑按钮
      editBtn: true,
      pickerOptions: {
        // disabledDate(time) {
        //   return time.getTime() > Date.now();
        // },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      disabled: false,
      //来源
      options: [],
      shortName: [],
      value: " ",
      value1: "",
      value2: "",
      radio: this.patientInfo.gender,
      flag: true,
      activeName: "first",
      listLoading: false,
      patientDetail: {},
      gender: "nv",
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      // tableData: [],
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      searchParams: {
        orderTime: [],
      },
      total: 0,
    };
  },
  filters: {
    genderFormat(v) {
      if (v === 1) {
        return "男";
      } else if (v === 2) {
        return "女";
      } else {
        return "未知";
      }
    },
  },
  methods: {
    changeListData(e) {
      this.dataList.m_category = e;
    },
    changeListData1(e) {
      this.dataList.buy_status = e;
    },
    //获取来源
    async getBasicConfigData() {
      const res = await getBasicConfig({ c_type: "from" });
      if (res.code === 200) {
        this.fromList = res.data;
        //将this.formList的每一项id名字变成form_id,name变成form
        this.fromList = this.fromList.map((item) => {
          return {
            from_id: item.id,
            from: item.name,
          };
        });
        console.log("this.formList", this.formList);
      }
    },
    //时间
    changeDate(e) {
      // this.patientInfo.created_at = e.toLocaleDateString().replace(/\//g, "-").replace(/(\d{4})-(\d{1})-(\d{1})/g, "$1-0$2-0$3");
      let date = new Date(e);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let dateStr = y + "-" + m + "-" + d;
      this.patientInfo.expired_at = dateStr;
    },
    //取消按钮
    handleCancel() {
      this.editBtn = true;
      this.flag = true;
      this.determineToCancel = false;
      //修改成原来样式
    },
    //来源change事件
    changeList() {
      //获取到我点击的当前this.formList的id和name,并且赋值给this.patientInfo
      const { from_id, from } = this.fromList.find(
        (item) => item.from_id === this.patientInfo.from
      );
      this.patientInfo.from_id = from_id;
      this.patientInfo.from = from;
      console.log("this.patientInfo111111", this.patientInfo);
    },
    //确认按钮
    handleNotarize() {
      if (this.patientInfo.gender != 1 && this.patientInfo.gender != 2) {
        this.$message.error("请选择性别");
        return;
      }
      let data = {};
      data.real_name = this.patientInfo.real_name;
      data.mobile = this.patientInfo.mobile;
      data.gender = Number(this.patientInfo.gender);
      data.age = Number(this.patientInfo.age);
      data.patient_ID = this.patientInfo.patient_ID;
      data.m_category = this.patientInfo.m_category;
      data.visit_level = this.patientInfo.visit_level;
      data.expired_at = this.patientInfo.expired_at;
      data.from_id = String(this.patientInfo.from_id);
      data.buy_status = this.patientInfo.buy_status;
      data.order_info = this.patientInfo.order_info;
      // data.from = this.patientInfo.from;
      //保存档案成功
      this.$confirm("是否将档案进行修改?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          putUpdate(this.patientInfo.id, data).then((res) => {
            console.log("res", res);
            if (res.code == 200) {
              (this.editBtn = true), (this.determineToCancel = false);
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.flag = true;
              this.$emit("getList", this.tableData);
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消此次操作",
          });
        });
    },
    //编辑按钮
    handleEditBtn() {
      //点击姓名变成输入框
      this.flag = false;
      this.editBtn = false;
      this.determineToCancel = true;
      //编辑消失
    },
    test() {},
    _patientDetail(rest) {
      this.listLoading = true;
      this.test(this.patientInfo.id)
        .then((response) => {
          let data = response;
          if (data.code === 200) {
            this.patientDetail = data.data;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    handleClick(tab, event) {
      console.log(tab, event);
      if (tab.name === "second") {
        this.editBtn = false;
      } else {
        if (this.determineToCancel == true) this.editBtn = false;
        else this.editBtn = true;
      }
    },
    closeDrawer() {
      console.log(123);
      this.$emit("closeDrawer", false);
    },
  },
};
</script>

<style scoped lang="scss">
.bottom_btn {
  position: fixed;
  bottom: 50px;
  right: 5%;
  background-color: #fff;
}
/*竖杠+后面文本的样式*/
.required {
  margin-top: -3px;
  display: inline-block;
  width: 4px;
  height: 14px;
  background-color: #2362fb;
  top: 20px;
}

.required + span {
  display: inline-block;
  margin-left: 4px;
  vertical-align: top;
  font-weight: bold;
  font-size: 14px;
}

/*caption-container样式*/
.caption-container {
  .caption-title {
    font-size: 16px;
    color: #111;
    font-weight: bold;
    margin-top: 0;
  }

  .caption-wrapper {
    display: flex;
    justify-content: left;
    align-items: center;

    .caption-avatar {
    }

    .caption-info {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      .caption-info-up {
        height: 30px;
        font-size: 16px;
        font-weight: bold;
        color: #111;

        .name {
          margin-right: 15px;
        }
      }

      .caption-info-down {
        display: flex;
        justify-content: left;
        align-items: center;
        height: 25px;

        .gender {
          width: 39px;

          .nv {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 14px;
            height: 14px;
            background-color: #e03030;
            color: #fff;
          }

          .nan {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 14px;
            height: 14px;
            background-color: #2362fb;
            color: #fff;
          }
        }

        .intro {
          display: flex;
          justify-content: left;
          align-items: center;
          background-color: #f7f7f7;
          color: #777;
          font-size: 14px;

          .intro-icon {
            margin-left: 5px;
            margin-right: 5px;
            padding-top: 1px;
            font-size: 16px;
          }

          .intro-title {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.gg-tabs {
  margin-top: 10px;
}

/*tabs下划线颜色*/
/deep/ .el-tabs__item {
  font-weight: bold;
}

/deep/ .is-active {
  color: #2362fb;
}

/deep/ .el-tabs__active-bar {
  background-color: #2362fb;
}

/*栅格布局*/
.bg-purple {
  /*background: #d3dce6;*/
}

.bg-purple-light {
  /*background: #e5e9f2;*/
}

.grid-content {
  border-radius: 4px;
  min-height: 35px;
  // width: 300px;
  line-height: 40px;
}

.patient-detail {
  font-size: 14px;
  color: #777;

  .detail-content {
    color: #111;
  }
}
::v-deep .el-input__inner {
  height: 30px;
  width: 250px;
}
</style>
