<template>
  <div>
    <input
      ref="excel-upload-input"
      class="excel-upload-input"
      type="file"
      accept=".xlsx, .xls"
      @change="handleClick($event)"
    />
    <el-button
      size="mini"
      type="success"
      style="padding: 6px 7px 7px 8px;background-color:rgb(35,98,251); border-color: rgb(35,98,251)"
      @click="handleDialogFormVisible"
    >
      <i class="iconfont icon-daoru" style="font-size: 14px" />
      <!--<a style="margin-left: 5px; font-size: 12px; " href="file/PatientRecords.xlsx">下载模板</a>-->
      <span style="margin-left: 5px; font-size: 12px; ">导入线索</span>
    </el-button>
    <el-dialog title="导入线索" :visible.sync="dialogFormVisible">
      <el-form :model="form" class="form" v-if="flag">
        <el-form-item label="导入文件" :label-width="formLabelWidth">
          <el-link
            type="primary"
            :underline="false"
            @click="handleSelectTheFile"
            >{{ fileName }}</el-link
          >
        </el-form-item>
        <el-form-item label="文件名称" :label-width="formLabelWidth">
          <el-input
            placeholder="文件名称"
            v-model="fileName"
            :disabled="true"
            style="width: 222.44px;"
          >
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="咨询产品" :label-width="formLabelWidth">
            <el-select
              v-model="valueConsulting"
              filterable
              placeholder="请输入内容"
            >
              <el-option
                v-for="item in productConsulting"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
        <div class="form-box">
          <el-form-item label="档案类型" :label-width="formLabelWidth">
            <el-select
              v-model="m_type"
              filterable
              placeholder="请输入内容"
              @change="handleType(m_type)"
            >
              <el-option
                v-for="item in FileType"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="档案类别" :label-width="formLabelWidth">
            <el-select
              v-model="m_category"
              filterable
              placeholder="请输入内容"
              @change="handleCategory(m_category)"
            >
              <el-option
                v-for="item in recordsSeries"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div v-else>
        <div class="top-data">
          <div style="width:40%">
            <span
              style="padding:2.5px 2px;background-color:#5677fc;border-radius:10px;margin-right:8px"
            ></span
            >导入明细
          </div>
          <div class="rg_data">
            <span
              >总数:
              <span style="color:black;font-size:18px;">{{
                tableData.length
              }}</span></span
            >
            <span
              >当前:
              <span style="color:black;font-size:18px;">{{
                tableData.length
              }}</span></span
            >
            <span
              >成功:
              <span style="color:greenyellow;font-size:18px;">{{
                successCount
              }}</span></span
            >
            <span
              >失败:
              <span style="color:red;font-size:18px;">{{
                failCount
              }}</span></span
            >
          </div>
        </div>
        <el-table :data="tableData" style="width:100%" height="300">
          <el-table-column prop="order_at" label="下单时间"> </el-table-column>
          <el-table-column prop="from" label="来源"> </el-table-column>
          <el-table-column prop="content" label="数量明细" width="180">
          </el-table-column>
          <el-table-column prop="name" label="收件人"> </el-table-column>
          <el-table-column prop="mobile" label="收件人电话"> </el-table-column>
          <el-table-column prop="address_info" label="地址" width="180">
          </el-table-column>
          <el-table-column prop="msg" label="验证信息"> </el-table-column>
        </el-table>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel" size="mini">取 消</el-button>
        <el-button
          type="primary"
          @click="handleNextStep"
          size="mini"
          v-if="next"
          >下一步</el-button
        >
        <el-button type="primary" @click="handleConfirm" size="mini" v-else
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getImportThread } from "@/api/chronicDisease/patientManage";
export default {
  name: "CDMImportTheClues",
  //下一步
  data() {
    return {
      next: "true",
      flag: true,
      btn: "下一步",
      fileName: "选择文件",
      formLabelWidth: "80px",
      dialogFormVisible: false,
      //表格数据
      tableData: [],
      formData: new FormData(),
      //档案类型
      FileType: [
        { id: 1, label: "热线" },
        { id: 2, label: "新线" },
        { id: 3, label: "资源" },
      ],
      m_type: "",
      agent_id: this.agent_id,
      //档案类别
      recordsSeries: [
        { id: 1, label: "采线" },
        { id: 2, label: "运营" },
        { id: 3, label: "回访" },
      ],
      m_category: "",
      form: {},
      m_file: "",
      // file_name: "",
      successCount: "", //成功次数
      failCount: "", //失败次数
    };
  },
  updated() {
    this.getList();
  },
  methods: {
    getList(rest){
      console.log(rest,111111)
    },
    //选择文件
    handleSelectTheFile() {
      this.$refs["excel-upload-input"].click();
    },
    handleType(type) {
      this.formData.append("m_type", type);
    },
    handleCategory(category) {
      // this.m_category = category;
      this.formData.append("m_category", category);
    },
    handleDialogFormVisible() {
      this.dialogFormVisible = true;
    },
    handleClick(e) {
      let file = e.target.files[0];
      console.log(file,'file');
      // this.formData = new FormData();
      this.formData.append("agent_id", localStorage.getItem("agent_id"));
      this.fileName = file.name;
      this.formData.append("file", file);
    },
    //点击取消
    handleCancel() {
      this.handleConfirm()
    },
    //点击下一步
    handleNextStep() {
      console.log(this.m_type);
      console.log(this.m_category);
      console.log(this);
      if (this.fileName == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
        return;
      }
      if (this.valueType == "") {
        this.$message({
          message: "请选择档案类型",
          type: "warning",
        });
        return;
      }
      if (this.valueSeries == "") {
        this.$message({
          message: "请选择档案类别",
          type: "warning",
        });
        return;
      }
      this.m_file = this.formData;
      getImportThread(this.m_file).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.data;
          this.failCount = res.data.failCount;
          this.successCount = res.data.successCount;
          this.next = false;
          this.flag = false;
          //清除数据
          // this.formData = new FormData();
          //刷新数据
          this.$emit("getList",this.tableData)
        }else{
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    handleConfirm() {
      this.flag = true;
      this.next = true,
      this.m_type = '',
      this.m_category = '',
      // this.file_name = ''
      this.fileName = '选择文件',
      this.$refs["excel-upload-input"].value = ''
      this.formData = new FormData()
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.form-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .el-form-item {
    margin-bottom: 25px;
  }
}
::v-deep .el-dialog__header {
  background-color: #f5f2f2;
}
::v-deep .el-dialog {
  border-radius: 15px;
  overflow: hidden;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}
.top-data {
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .rg_data {
    flex: 1;
    display: flex;
    justify-content: space-evenly;
    font-size: 20px;
  }
}
</style>
